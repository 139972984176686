import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImagesCalendar from '../components/HeroImagesCalendar'

const Calendar = () => {
  return (
    <div>
      <Navbar />
      <HeroImagesCalendar heading='Check our Availability'/>
      <Footer />
    </div>
  )
}

export default Calendar
