import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Form from '../components/Form'
import Footer from '../components/Footer'
import HeroImages from '../components/HeroImages'
import Faq from '../components/Faq'


const Contact = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What's the turnaround time for my project?",
      answer:
        "Once we have scanned your property, it takes about 7 days to perfect the design. Once the design is complete, we send it to you to make sure you’re happy with the outcome.",
      open: false
    },
    {
      question: "How long does it take to photograph my property with 3D virtual tours, property photography, or aerial photography from Ekelo?",
      answer: "It will take 1-2 hours depending on the square footage and the package chosen for that scan.",
      open: false
    },
    {
      question:
        "How do I prepare my property for a shoot?",
      answer: "Clean and present the area for how you’d like it to be shown in the final product. Open all doors to rooms which you’d like us to scan, and close all others. Turn on all the lights for best exposure. Before the scan, we will personally walk through the home with you to make sure it's ready!",
      open: false
    },
    {
      question:
        "How much do Ekelo services cost?",
      answer: "The pricing of your package depends on a variety of factors including which options you have selected and the square footage of your house. For a price you can depend on, please use our price calculator.",
      open: false
    },
    {
      question:
        "How much lead time do I need to schedule?",
      answer: "Feel free to use our calendar to schedule an appointment or call at your convenience.",
      open: false
    },
    {
      question:
        "How much is charged for travel?",
      answer: "No charge for any property within 25 miles. We charge $1/mile past 25 miles.",
      open: false
    },
    {
      question:
        "Is my quote final?",
      answer: "No, you can always go back, make a new quote, and customize your package.",
      open: false
    },
    {
      question:
        "What is the invoicing process?",
      answer: "You will receive an invoice via email after we have scanned your property and delivered you with a scan you’re happy with.",
      open: false
    }
  ]);

  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <div>
      <Navbar />
      <HeroImages heading='Contact us' text='1-949-979-5454' />
      <Form />
      <div className="faqs">
        {faqs.map((faq, index) => (
          <Faq faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default Contact
