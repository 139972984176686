
import React from 'react'
import { Link } from 'react-router-dom'
import './CalculatorStyles.css'

const Calculator = () => {
  return (
    <div className='calculator'>
      <div className='card-container'>
        <div className='card'>
          <h3>- Standard -</h3>
          <h4> Ricoh 3D Scan </h4>
          <span className='bar'></span>
          <p className='dollars'>$150</p>
          <p>+ 10¢/SqFt over 1,000 SqFt</p>
          <Link to='#/' className='btn btn-light'>Choose</Link>
        </div>

        <div className='card'>
          <h3>- Premium -</h3>
          <h4> Matterport 3D Scan </h4>
          <span className='bar'></span>
          <p className='dollars'>$150</p>
          <p>+ 20¢/SqFt over 1,000 SqFt</p>
          <Link to='#/' className='btn btn-light'>Choose</Link>
        </div>
        
      </div>
      <div className='card card-add'>
          <h3>- Add Ons -</h3>
          <span className='bar'></span>
          <p>$250 Up to 35 photos</p>
          <Link to='#/' className='btn btn-light'>Choose</Link>
          <p>$30 Floorplan</p>
          <Link to='#/' className='btn btn-light'>Choose</Link>
        </div>
    </div>
  )
}

export default Calculator
