import React from 'react'
import './AboutPageStyles.css'
import { Link } from 'react-router-dom'
import Agent from '../assets/agent1.jpg'
import Agent2 from '../assets/agent2.jpg'
import Agent3 from '../assets/agent3.jpg'

const AboutPage = () => {
  return (
    <div className='about'>
        <div className='right'>
            <div className='img-container'>
                <div className='image-stack bottom'>
                    <img src={Agent} className='img' alt='Agent 2' />
                </div>
            </div>
        </div>
        <div className='left'>
            <h1>Michael</h1>
            <p>
            Michael has 6 years of customer service experience, including 2 years
            of customer service management. He has an AA in Communications, AS in Physics, 
            and AS in Mathematics.
            </p>
            <a href="mailto:mrussell@ekelo.net"><button className='btn'>Contact</button></a>
        </div>
        <div className='left'>
            <h1>Aaron</h1>
            <p>
            4+ years of customer service and office experience with a background in
            Business Management and Administration. Currently pursuing a Bachelors in
            Computer Science.
            </p>
            <a href="mailto:aaron@ekelo.net"><button className='btn'>Contact</button></a>
        </div>
        <div className='right'>
            <div className='img-container'>
                <div className='image-stack bottom'>
                    <img src={Agent3} className='img' alt='Agent 3' />
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutPage
