import React, {useState, useEffect } from 'react'

import Navbar from '../components/Navbar'
import Video from '../components/Video'
import Footer from '../components/Footer'
import Services from '../components/Services'
import JsonData from "../data/data.json";

const Home = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  return (
    <div>
      <Navbar />
      <Video />
      <Services data={landingPageData.Services} />
      <Footer />
    </div>
  )
}

export default Home
