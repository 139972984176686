import React from 'react'
import './FooterStyle.css'
import {FaFacebook, FaLinkedin, FaMailBulk, FaPhone, FaInstagram} from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
      <div className='footer'>
        <div className='footer-container'>
          <div className='left'>
            <div id='list'>
              <h2>About the company</h2>
              <p>"Showcasing your property shouldn’t have to be a pain and with Ekelo, it isn’t! Our 3D
scans allow prospective buyers and clients to immerse themselves in what you have to
offer. This allows people to feel as if they are actually there without having to be
physically present."
                </p>
            <div className='social'>
              <FaFacebook size={30} style={{ color: '#1B5E20', marginRight: '1rem' }} />
              <FaLinkedin size={30} style={{ color: '#1B5E20', marginRight: '1rem' }} />
              <FaInstagram size={30} style={{ color: '#1B5E20', marginRight: '1rem' }} />
            </div>
            </div>
          </div>
          <div className='center'>
            <h3>
              <Link to='/'>Home</Link>
            </h3>
            <h3>
              <Link to='/about'>About</Link>
            </h3>
            <h3>
              <Link to='/pricing'>Calculator</Link>
            </h3>
            <h3>
              <Link to='/calendar'>Calendar</Link>
            </h3>
            <h3>
              <Link to='/faq'>FAQ</Link>
            </h3>
            </div>
            <div className='right'>
              <div className='phone'>
                  <p><FaPhone size={20} style={{ color: '#1B5E20', marginRight: '0.5rem' }} /> 1-949-979-5454</p>
              </div>
              <div id='email'>
                <p><FaMailBulk size={20} style={{ color: '#1B5E20', marginRight: '0.5rem' }} /><a href="mailto:Inquiries@ekelo.net">Inquiries@ekelo.net</a></p>
              </div>
              <h4>Copyright © 2022 Ekelo - All Rights Reserved.</h4>
            </div>
        </div>
      </div>
  )
}

export default Footer
