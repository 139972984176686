import React, { Component } from 'react'
import './HeroImagesStyles.css' 
import { PopupButton } from 'react-calendly'

export class HeroImages extends Component {
  render() {
    return (
      <div className='hero'>
      <div className='content'>
        <h1>{this.props.heading}</h1>
        <div className='buttons'>
          <PopupButton
            url="https://calendly.com/ekelo/appointment-with-ekelo"
            rootElement={document.getElementById("root")}
            text="Book an Appointment"
            className='btn btn-light'
              />
          <PopupButton
            url="https://calendly.com/ekelo/30min"
            rootElement={document.getElementById("root")}
            text="Schedule a call"
            className='btn btn-light'
              />
        </div>
      </div>
    </div>
    )
  }
}

export default HeroImages
