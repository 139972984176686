import React from 'react'
import './VideoStyles.css'

import estateVideo from '../assets/video3.mp4'

const Video = () => {
  return (
    <div className='hero'>
      <video autoPlay loop muted playsInline id='video'>
        <source src={ estateVideo } type='video/mp4' />
      </video>
      <div className='content'>
          <h1>Meet The Team</h1>
          <p>We're Making Real Estate Better.</p>
      </div>
    </div>
  )
}

export default Video
