import React from 'react'
import { Link } from 'react-router-dom'
import './VideoStyles.css'

import estateVideo from '../assets/video.mp4'

const Video = () => {
  return (
    <div className='hero'>
      <video autoPlay loop muted playsInline id='video'>
        <source src={ estateVideo } type='video/mp4' />
      </video>
      <div className='content'>
          <h1>Real Estate Simplified</h1>
          <p>In-person experience from a distance</p>
        <div>
          <Link to='/pricing' className='btn btn-light'>Pick your plan</Link>
          <Link to='/calendar' className='btn btn-light'>Contact Us</Link>
        </div>
      </div>
    </div>
  )
}

export default Video
